<template>
  <Auth />
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h5 class="mb-0">Setoran Deposit</h5>
      <p class="mb-0 text-sm">Saldo tambahan dari setoran deposit manual.</p>
    </div>
    <div class="card-body" style="border-top: 1px solid #eeeeee">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6 text-end">
          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="showAddForm"
            >
              + Tambah Dana
            </button>
          </div>
          &nbsp;
        </div>
      </div>
      <div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            v-model="pagination.perPage"
            class="select-primary pagination-select"
            placeholder="Per page"
          >
            <el-option
              v-for="item in pagination.perPageOptions"
              :key="item"
              class="select-primary"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <div>
            <el-input
              v-model="searchQuery"
              type="search"
              class="mb-3"
              clearable
              prefix-icon="el-icon-search"
              style="width: 200px"
              placeholder="Search records"
              aria-controls="datatables"
            >
            </el-input>
          </div>
        </div>
        <el-skeleton v-if="loadingTable" :rows="3" animated />
        <div v-else class="table-responsive">
          <el-table
            style="font-size: 12px"
            :data="queriedData"
            row-key="id"
            header-row-class-name="thead-light"
            @selection-change="selectionChange"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              v-bind="column"
            >
            </el-table-column>
            <el-table-column align="left" label="Saldo">
              <template #default="props">
                {{ props.row.display_fund }}<br />
                <small class="text-secondary"
                  ><b>({{ props.row.display_fund_tobe_transfered }})</b></small
                >
              </template>
            </el-table-column>
            <el-table-column align="left" label="Type">
              <template #default="props">
                {{ props.row.payment_type }}
              </template>
            </el-table-column>
            <el-table-column align="left" label="Status">
              <template #default="props">
                <span
                  v-if="props.row.status == 'pending'"
                  class="badge badge-sm badge-secondary"
                  >{{ props.row.status }}</span
                >
                <span
                  v-else-if="props.row.status == 'success'"
                  class="badge badge-sm badge-success"
                  >{{ props.row.status }}</span
                >
                <span
                  v-else-if="props.row.status == 'expired'"
                  class="badge badge-sm badge-danger"
                  >{{ props.row.status }}</span
                >
              </template>
            </el-table-column>
            <el-table-column align="left" label="Bill">
              <template #default="props">
                <a
                  href="#"
                  @click.prevent="handleViewBill(props.$index, props.row)"
                  ><i class="fas fa-print text-primary"></i
                ></a>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <br />
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <base-pagination
            v-model="pagination.currentPage"
            class="pagination-no-border"
            :per-page="pagination.perPage"
            :total="total"
          >
          </base-pagination>
        </div>
      </div>
    </div>
  </div>
  <modal v-model:show="modals.classic">
    <div class="p-2">
      <h5>Tambah Setoran Deposit</h5>
      <form @submit.prevent="onSubmit">
        <input v-model="form.id" type="hidden" />
        <div class="row">
          <div class="col-md-7">
            <label class="form-label">Masukkan Jumlah Uang</label>
            <input
              v-model="form.fund"
              type="number"
              class="form-control form-control-lg"
              required
            />
          </div>
          <div class="col-md-5">
            <label class="form-label">Cara Setor</label>
            <select
              v-model="form.payment_type"
              class="form-select form-select-lg"
              required
            >
              <option
                v-for="select in selects.payment_types"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
        </div>

        <hr style="margin-bottom: -20px" />
        <argon-button
          color="primary"
          variant="gradient"
          class="my-4 mb-2"
          :loading="isLoading"
          >Submit Data</argon-button
        >
        &nbsp;
        <button
          type="button"
          class="btn btn-link"
          style="margin-top: 35px"
          @click="modals.classic = false"
        >
          Close
        </button>
      </form>
    </div>
  </modal>

  <modal v-model:show="modals.classic2" size="lg">
    <div class="p-4">
      <el-skeleton
        v-if="skeletonLoadingOfficialBankAccount"
        :rows="3"
        animated
      />
      <div v-if="showOfficialBankAccount">
        <BillDepositInformation
          :income-deposit-selected="incomeDepositSelected"
          :official-bank-account="officialBankAccount"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Auth from "@/components/auth/Auth";
import ArgonButton from "@/components/ArgonButton.vue";
import Modal from "@/components/Modal";
import Swal from "sweetalert2";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElSkeleton,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import BillDepositInformation from "@/components/Finance/BillDepositInformation";

export default {
  name: "IncomeDeposit",
  components: {
    BasePagination,
    Auth,
    ArgonButton,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    Modal,
    ElSkeleton,
    BillDepositInformation
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      urlread: "/api/v1/income_deposits/read",
      urlOfficialBankAccount: "/api/v1/official_bank_account/read",
      urlstore: "/api/v1/income_deposits/store",
      urlupdate: "/api/v1/income_deposits/update",
      urldelete: "/api/v1/income_deposits/delete",

      dataTable: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["fund", "payment_type", "status"],
      tableColumns: [
        {
          type: "selection",
        },
      ],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      loadingTable: true,

      officialBankAccount: [],
      skeletonLoadingOfficialBankAccount: false,
      showOfficialBankAccount: false,
      fundToBeTransfered: 0,
      displayFund: 0,
      incomeDepositSelected: [],

      selects: {
        payment_types: [
          {
            value: "Transfer",
            name: "Transfer Bank",
          },
        ],
      },

      form: {
        id: "",
        fund: "50000",
        payment_type: "Transfer",
      },
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  created() {
    this.getTables();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },

    async getTables() {
      try {
        this.loadingTable = true;
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        //console.log(response);
        this.datas = response.data.data.data;
        this.tableData = this.datas;

        // callback grandtotal_deposit
        this.$emit("displaygrandtotalincomedeposit", response.data.data.display_grandtotal_income_deposit);
        this.loadingTable = false;
      } catch (error) {
        console.log(error);
        this.loadingTable = false;
        this.errorRedirect500(error);
      }
    },
    async getOfficialBankAccount() {
      if (this.officialBankAccount == "") {
        try {
          this.skeletonLoadingOfficialBankAccount = true;
          const response = await axios.get(this.urlOfficialBankAccount, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          console.log(response);
          this.officialBankAccount = response.data;
          //console.log(this.datas);
          this.skeletonLoadingOfficialBankAccount = false;
          this.showOfficialBankAccount = true;
        } catch (error) {
          console.log(error);
          this.skeletonLoadingOfficialBankAccount = false;
          this.errorRedirect500(error);
        }
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },

    refreshTable() {
      this.getTables();
    },
    clearForm() {
      for (let x in this.form) {
        this.form[x] = "";
      }
    },
    showAddForm() {
      this.modals.classic = true;
    },
    async showEditForm(index, row) {
      this.modals.classic = true;
      this.clearForm();
      this.loadingService();
      try {
        const response = await axios({
          method: "get",
          url: this.urlread + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let data = response.data;
        for (let x in this.form) {
          this.form[x] = data[x];
        }

        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        let sendmethod = "post";
        let sendurl = this.urlstore;
        if (this.form.id != "") {
          // update
          sendmethod = "put";
          sendurl = this.urlupdate;
        }
        const response = await axios({
          method: sendmethod,
          url: sendurl,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          this.showViewBill(response.data.data);
          // load table
          this.getTables();
          // close modal
          this.modals.classic = false;
          // clear form
          //this.clearForm();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.errorRedirect500(error);
      }
    },
    handleViewBill(index, row) {
      if (row.payment_type == "Transfer") {
        this.showViewBill(row);
      }
    },
    showViewBill(row) {
      console.log(row);
      this.getOfficialBankAccount();
      this.modals.classic = false;
      this.modals.classic2 = true;
      this.incomeDepositSelected = row;
    },

    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
