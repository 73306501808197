<template>
  <Auth />
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h5 class="mb-0">Riwayat Pencairan</h5>
      <p class="mb-0 text-sm">Daftar pencairan yang sudah dibuat.</p>
    </div>
    <div class="card-body" style="border-top: 1px solid #eeeeee">
      <div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            v-model="pagination.perPage"
            class="select-primary pagination-select"
            placeholder="Per page"
          >
            <el-option
              v-for="item in pagination.perPageOptions"
              :key="item"
              class="select-primary"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <div>
            <el-input
              v-model="searchQuery"
              type="search"
              class="mb-3"
              clearable
              prefix-icon="el-icon-search"
              style="width: 200px"
              placeholder="Search records"
              aria-controls="datatables"
            >
            </el-input>
          </div>
        </div>
        <el-skeleton v-if="loadingTable" :rows="3" animated />
        <div v-else class="table-responsive">
          <el-table
            style="font-size: 12px"
            :data="queriedData"
            row-key="id"
            header-row-class-name="thead-light"
            @selection-change="selectionChange"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              v-bind="column"
            >
            </el-table-column>
            <el-table-column align="left" label="Bank Info">
              <template #default="props">
                {{ props.row.bank_name }}<br />
                {{ props.row.bank_account_name }}<br />
                {{ props.row.bank_account_number }}
              </template>
            </el-table-column>
            <el-table-column align="left" label="Amount">
              <template #default="props">
                {{ props.row.display_amount }}<br />
              </template>
            </el-table-column>
            <el-table-column align="left" label="Created at">
              <template #default="props">
                {{ props.row.display_created_at }}
              </template>
            </el-table-column>
            <el-table-column align="left" label="Status">
              <template #default="props">
                <span class="badge bg-secondary" v-if="props.row.status == 'request'">{{ props.row.status }}</span>
                <span class="badge bg-success" v-if="props.row.status == 'paid'">{{ props.row.status }}</span>
                <span class="badge bg-danger" v-if="props.row.status == 'canceled'">{{ props.row.status }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <br />
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <base-pagination
            v-model="pagination.currentPage"
            class="pagination-no-border"
            :per-page="pagination.perPage"
            :total="total"
          >
          </base-pagination>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import Auth from "@/components/auth/Auth";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElSkeleton,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

export default {
  name: "WithdrawHistory",
  components: {
    BasePagination,
    Auth,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    ElSkeleton,
  },
  data() {
    return {
      urlread: "/api/v1/withdraw_requests/read",

      dataTable: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["amount", "status"],
      tableColumns: [],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      loadingTable: true,

    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  created() {
    this.getTables();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },

    async getTables() {
      try {
        this.loadingTable = true;
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        //console.log(response);
        this.datas = response.data.data;
        this.tableData = this.datas;

        this.loadingTable = false;
      } catch (error) {
        console.log(error);
        this.loadingTable = false;
        this.errorRedirect500(error);
      }
    },
    
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },

    refreshTable() {
      this.getTables();
    },
  
  },
};
</script>
