s
<template>
  <Auth />
  <div class="card">
    <div class="card-header">
      <h5 class="mb-0">Request Pencairan</h5>
      <p class="mb-0 text-sm">
        Sudah punya Saldo?. Lakukan request pencairan disini.
      </p>
    </div>
    <div class="card-body" style="border-top: 1px solid #eeeeee">
      <form @submit.prevent="onSubmit">
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Jumlah Saldo Tersedia</label
          >
          <span v-if="balance > 0">
            <h3 class="text-success">{{ this.display_balance }}</h3>
          </span>
          <span v-else-if="balance == 0">
            <h3 class="text-success">{{ this.display_balance }}</h3>
          </span>
          <span v-else-if="balance < 0">
            <h3 class="text-danger">{{ this.display_balance }}</h3>
          </span>
          <div class="form-text">
            <div v-if="balance > 0">
              Total Saldo saat ini yang bisa dicairkan sekarang.
            </div>
            <div v-else><i>Anda belum bisa mencairkan saldo.</i></div>
          </div>
        </div>
        <div v-if="balance > 0">
          <div class="mb-3">
            <label class="form-label">Request Jumlah Pencairan</label>
            <input
              v-model="form.amount"
              type="number"
              :max="balance"
              class="form-control"
              required
            />
          </div>
          <div class="mb-3">
            <label class="form-label"
              >Payment Info |
              <a
                href="#"
                @click.prevent="handleUpdatePaymentInfo"
                class="text-primary"
                >Click here for update PAYMENT INFO</a
              ></label
            >
            <input type="hidden" v-model="form.user_payment_info_id" required />
            <div v-if="myPaymentInfo != ''" class="text-sm">
              Nama Bank: <b>{{ this.myPaymentInfo.bank_name }}</b
              ><br />
              Pemilik Akun: <b>{{ this.myPaymentInfo.bank_account_name }}</b
              ><br />
              No. Rekening: <b>{{ this.myPaymentInfo.bank_account_number }}</b>
            </div>
            <div v-else class="text-sm"><i>Info pembayaran belum dibuat.</i></div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <label class="form-label"
                >PIN TOKEN |
                <a
                  href="#"
                  @click.prevent="handleRequestPinToken"
                  class="text-primary"
                  >Click here for request PIN TOKEN</a
                ></label
              >
              <input
                v-model="form.pintoken"
                type="text"
                maxlength="20"
                class="form-control"
                style="width: 200px"
                required
              />
            </div>
          </div>
          <div class="mt-4">
            <argon-button
              color="primary"
              variant="gradient"
              class="my-4 mb-2"
              :loading="isLoading"
              >Create Request</argon-button
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Auth from "@/components/auth/Auth";
import axios from "axios";
import Swal from "sweetalert2";
import { ElLoading } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
export default {
  name: "WithdrawRequest",
  components: {
    Auth,
    ArgonButton,
  },
  props: {
    balance: { required: false, type: Number, default: 0 },
    display_balance: { required: false, type: String, default: "" },
  },
  data() {
    return {
      urlRequestPinToken: "/api/v1/member/mypayment_info/request_pintoken",
      urlUserPaymentInfo: "/api/v1/member/mypayment_info",
      urlWithdrawRequest: "/api/v1/withdraw_requests/create",
      form: {
        amount: "",
        pintoken: "",
        user_payment_info_id: "",
      },
      myPaymentInfo: [],
    };
  },
  created() {
    this.getAmount();
    this.getMyPayment();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    getAmount() {
      this.form.amount = this.balance;
    },
    async getMyPayment() {
      this.loadingService();
      try {
        const response = await axios.get(this.urlUserPaymentInfo, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.myPaymentInfo = response.data.data;
        this.form.user_payment_info_id = this.myPaymentInfo.id;

        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async handleRequestPinToken() {
      this.loadingService();
      try {
        const response = await axios({
          method: "get",
          url: this.urlRequestPinToken,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        //console.log(response.data);
        this.loadingService().close();
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire({
            title: "Pin Token Sent!",
            html: response.data.message,
            icon: "success",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    handleUpdatePaymentInfo() {
      this.$router.push({ path: "/account/settings" });
    },
    async onSubmit() {
      this.isLoading = true;
      this.loadingService();
      try {
        const response = await axios({
          method: "post",
          url: this.urlWithdrawRequest,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        this.loadingService().close();
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire({
            title: "Good job!",
            html: response.data.message,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });

          // callback
          this.$emit("newwithdrawrequestcallback", true);
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
  },
};
</script>
